<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:33:34
 * @LastEditTime: 2023-03-22 18:37:38
 * @Descripttion: 课程表时间设置
-->
<style lang="scss" scoped>
.timetable {
    @include innerPage;
    @include pageHead(-42px);
    @include defalutTable(calc(100% - 162px), 40px);
    @include pageFoot;
}

.dialog-container {
    box-sizing: border-box;
    padding: 34px 4px 20px 18px;

    .timetable-form {
        width: calc(100% + 20px);
        box-sizing: border-box;
        padding-right: 20px;
    }

    .add {
        margin-left: 70px;
        margin-top: 20px;
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }

    .form-item {
        width: 100%;
        @include flexBox;

        .del,
        .el-button {
            width: 26px;
        }

        .del {
            margin-left: 18px;
            flex-shrink: 0;
            @include flexBox;
        }

        .el-button {
            height: 26px;
            font-size: 14px;
        }
    }

    ::v-deep {
        .el-date-editor {

            &::before,
            &::after {
                display: none;
            }

            &:read-only {
                background: #f6f7fa;
            }
        }
    }
}
</style>

<template>
    <section class="timetable">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" size="small" @click="showDialog">
                    课程时间设置 +
                </el-button>
                <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                    返回
                </el-button>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" label="课程时间" min-width="30%">
                        <template slot-scope="scope">
                            {{ scope.row.sccla_sch_tim_starttime }} ~ {{ scope.row.sccla_sch_tim_endtime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="创建时间" min-width="39%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="31%">
                        <template slot-scope="scope">
                            <el-button type="custom_primary" size="mini" plain @click="showDialog(scope.row)">编辑</el-button>
                            <el-button type="custom_danger" size="mini" plain @click="delTime(scope.row.sccla_sch_tim_id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog :title="timetableForm.sccla_sch_tim_id && '编辑' || '课程时间设置'" width="530px" :visible.sync="timetableDialog"
            :show-close="false" @close="hideDialog">
            <div class="dialog-container">
                <div class="timetable-form">
                    <el-form label-width="70px" ref="timetableForm" :model="timetableForm" :rules="rules">
                        <el-form-item label="课程时间" prop="time" v-if="timetableDialog">
                            <el-time-picker prefix-icon="none" range-separator="至" value-format="HH:mm" format="HH:mm"
                                align="center" is-range v-model="timetableForm.time" :clearable="false" :editable="false" />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="addTime" :disabled="btnload" v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $scheduleTime, $scheduleTimeAdd, $scheduleTimeEidt, $scheduleTimeDel } from "@api/home"
import customPagination from "@comp/customPagination"
export default {
    name: 'home_timetable',
    components: { customPagination },
    data() {
        let validateTime = (rule, value, callback) => {
            if (!value || !value.length) {
                callback(new Error('请选择课程时间'));
            } else {
                callback();
            }
        }
        return {
            tableData: [],
            tablePage: {
                pageIndex: 1,
                total: 0
            },
            timetableDialog: false,
            timetableForm: {
                time: null,
            },
            btnload: false,
            rules: {
                time: [{
                    required: true, validator: validateTime, trigger: 'change',
                }],
            }
        };
    },
    created() {
        this.getTimeTable();
    },
    methods: {
        /** 获取课程表时间列表 */
        async getTimeTable() {
            let { data: res } = await $scheduleTime(this.tablePage.pageIndex);
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /** 添加课程时间点 */
        addTime() {
            this.$refs.timetableForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let { time, sccla_sch_tim_id: id } = this.timetableForm;
                    if (id) return this.editTime();
                    let res = await $scheduleTimeAdd(time[0], time[1]);
                    this.btnload = false;
                    if (res) {
                        this.$message.success('添加成功');
                        this.hideDialog();
                        this.getTimeTable();
                    }
                }
            })
        },
        /** 编辑时间 */
        async editTime() {
            let { time, sccla_sch_tim_id: id } = this.timetableForm;
            let params = {
                sccla_sch_tim_id: id,
                sccla_sch_tim_starttime: time[0],
                sccla_sch_tim_endtime: time[1],
            }
            let res = await $scheduleTimeEidt(params);
            this.btnload = false;
            if (res) {
                this.hideDialog();
                this.$message.success('编辑成功');
                this.getTimeTable();
            }
        },
        /**
         * 删除课程时间
         * @param {number} id 时间id
         */
        delTime(id) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前课程时间？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let res = await $scheduleTimeDel(id);
                if (res) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '当前课程时间已删除！'
                    });
                    this.tablePage.total = this.tablePage.total - 1 < 0 && 0 || this.tablePage.total - 1;
                    if (this.tableData.length == 1 && this.tablePage.pageIndex != 1) {
                        this.tablePage.pageIndex = this.tablePage.pageIndex - 1;
                    }
                    this.getTimeTable();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getTimeTable();
        },
        /** 开启弹窗 */
        showDialog(data) {
            if (data.sccla_sch_tim_id) {
                let time = [data.sccla_sch_tim_starttime, data.sccla_sch_tim_endtime];
                this.timetableForm = {
                    sccla_sch_tim_id: data.sccla_sch_tim_id,
                    time
                }
            }
            this.timetableDialog = true;
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.timetableDialog = false;
            this.resetForm('timetableForm');
            this.timetableForm = { time: null };
        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        }
    },
}
</script>